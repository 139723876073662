import React from 'react';
import {Link} from 'gatsby';

import Menu from './Menu';

import css from './Header.module.css';

const PAGES = [
  {slug: 'events', title: 'Events'},
  {slug: 'travel', title: 'Travel'},
  {slug: 'accommodations', title: 'Accommodations'},
  {slug: 'thingsToDo', title: 'Things to Do'},
  {slug: 'registry', title: 'Registry'},
  {slug: 'faq', title: 'FAQ'},
];

const Header = ({siteTitle}) => (
  <div className={css.container}>
    <div className={css.titleAndMenu}>
      <h1><Link to="/" className={css.titleLink}>{siteTitle}</Link></h1>
      <Menu pages={PAGES}/>
    </div>
    <div className={css.divider}/>
  </div>
)

export default Header
