import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames';

import css from './Menu.module.css';

class Menu extends React.Component {
  state = {
    menuShowing: false,
  }
  onHamburgerClick = () => {
    this.setState({menuShowing: !this.state.menuShowing});
  }
  render() {
    const {pages} = this.props;
    const {menuShowing} = this.state;
    return (
      <div>
        <div className={css.hamburger} onClick={this.onHamburgerClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={classNames(css.container, {[css.toggled]: menuShowing})}>
          <div className={css.links}>
            {pages.map(page => (
              <Link
                to={page.slug}
                key={page.slug}
                className={css.link}
                activeClassName={css.activeLink}
              >
                {page.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Menu
