import React from 'react'
import Img from 'gatsby-image';
import classNames from 'classnames';

import css from './Blurb.module.css';


class Blurb extends React.Component {
  renderImage() {
    const {image, titleUrl} = this.props;
    return (
      <div className={css.image}>
        <a target="_blank" href={titleUrl} rel="noopener noreferrer"> 
          <Img fixed={image} />
        </a>
      </div>
    )
  }
  render() {
    const {anchor, isFirst, children, title, titleUrl, image} = this.props;
    return (
      <div id={anchor} className={classNames(css.blurb, {[css.blurb_isFirst]: isFirst})}>
        {image && this.renderImage()}
        <div className={css.text}>
          {title && (
            <div className={css.title}>
              {titleUrl ? <a target="_blank" href={titleUrl} rel="noopener noreferrer">{title}</a> : title}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default Blurb
