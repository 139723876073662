import React from 'react'

import Blurb from '../components/Blurb'
import Layout from '../components/layout'
import SEO from '../components/SEO';

const EventsPage = () => (
  <Layout>
    <SEO title="Events"/>
    <Blurb isFirst={true}>
      For day-of shuttle info, see <a href="/accommodations">Accommodations</a>.
      <footer />
    </Blurb>
    <Blurb>
      We’re excited to have you here to celebrate with us, and we hope we’ll keep you busy
      and show you a fun time. Together with our parents, we’ll be hosting several events
      throughout the weekend. Our home base for the duration will be the Deer Mountain Inn,
      which has a great restaurant and a lovely bar, plus a comfortable living room and
      expansive grounds. As our guests, you
      should treat the Inn as a home away from home for the weekend and stop by to hang out
      at any time.
    </Blurb>

    <h2>Friday, September 20</h2>
    <Blurb>
      <strong>6:30 p.m.: Rehearsal Dinner</strong><br/>
      <strong>Hosted by Ellen and Sandy Oxfeld</strong><br/>
      <strong>Tuckaway Cottage, Deer Mountain Inn</strong><br/>
      <strong>Attire: casual <a href="/faq#dress">(and bring a sweater)</a></strong><br/>
      Our families and friends in our wedding party will join us for dinner at the Oxfelds’ home
      for the weekend, Tuckaway Cottage, on the Deer Mountain property. Separate invitation to
      follow.
    </Blurb>
    
    <Blurb>
      <strong>9 p.m.: Welcome Drinks, Desserts, and Toasts</strong><br/>
      <strong>Tuckaway Cottage, Deer Mountain Inn</strong><br/>
      After dinner, Ellen and Sandy invite everyone to join us for drinks, desserts,
      toasts, and general merriment. There will not be a montage.
    </Blurb>

    <Blurb>
      <em>
        You can access Tuckaway Cottage via the marked driveway just north
        of the main Deer Mountain Inn entrance on Route 25. There’s not a lot of parking
        so please plan to carpool or take a taxi.
      </em>
    </Blurb>

    <h2>Saturday, September 21</h2>
    <Blurb>
      <strong>5 p.m.: Wedding Ceremony</strong><br/>
      <strong>The Hilltop Clearing at Deer Mountain Inn</strong><br/>
    </Blurb>

    <Blurb >
      <strong>6 p.m.: Wedding Reception</strong><br/>
      <strong>Deer Mountain Inn</strong><br/>
      <strong>Cocktail attire (<a href="/faq#dress">with some caveats</a>)</strong>
      <br/><br/>
      <em>Shuttles from the hotels will deliver you to Deer Mountain, where you can walk up 
        the mountain or take a shuttle up. If you’re driving to the Inn, park in the lot and 
        then you can either walk or take a shuttle up. After the ceremony, you can either 
        walk down to the Inn, though probably not in heels, or take a quick shuttle ride back 
        down. (We'll be riding with <a href="/faq#benno">Ben</a>.) At the end of the evening, 
        taxis will be available for anyone who prefers not to drive home. You can also feel 
        free to leave your car in the Inn’s lot overnight.
      </em>
    </Blurb>

    <h2>Sunday, September 22</h2>

    <Blurb>
      <strong>10 a.m. to 12 p.m.: Morning-After Brunch</strong><br/>
      <strong>Hosted by Mary and Chris Strasser</strong><br/>
      <strong>Deer Mountain Inn</strong><br/>
      Mary and Chris invite everyone to join us for breakfast and farewells at the Deer Mountain
      Inn. They serve a house-made smoked-trout thing! (It’s like lox spread, but more
      Catskills-y.) Separate invitation to follow.
    </Blurb>
  </Layout>
)

export default EventsPage
